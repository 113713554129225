var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["@naturehouse/design-system/components/protons/icon/Icon.macro.njk"] = require( "@naturehouse/design-system/components/protons/icon/Icon.macro.njk" );




var shim = require("/builds/natuurhuisje/nature.house/symfony/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["design-system/v3/components/atoms/rating/Rating.html.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("@naturehouse/design-system/components/protons/icon/Icon.macro.njk", false, "design-system/v3/components/atoms/rating/Rating.html.njk", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("iconTemplate", t_1);
output += "\n\n";
var t_4;
t_4 = env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "variant"),"nature");
frame.set("variant", t_4, true);
if(frame.topLevel) {
context.setVariable("variant", t_4);
}
if(frame.topLevel) {
context.addExport("variant", t_4);
}
output += "\n";
var t_5;
t_5 = env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "value"),0);
frame.set("value", t_5, true);
if(frame.topLevel) {
context.setVariable("value", t_5);
}
if(frame.topLevel) {
context.addExport("value", t_5);
}
output += "\n";
var t_6;
t_6 = 0;
frame.set("min", t_6, true);
if(frame.topLevel) {
context.setVariable("min", t_6);
}
if(frame.topLevel) {
context.addExport("min", t_6);
}
output += "\n";
var t_7;
t_7 = 5;
frame.set("max", t_7, true);
if(frame.topLevel) {
context.setVariable("max", t_7);
}
if(frame.topLevel) {
context.addExport("max", t_7);
}
output += "\n\n";
var t_8;
t_8 = {"name": "leaf","size": 1.125,"custom": true};
frame.set("icon", t_8, true);
if(frame.topLevel) {
context.setVariable("icon", t_8);
}
if(frame.topLevel) {
context.addExport("icon", t_8);
}
output += "\n\n";
if(runtime.contextOrFrameLookup(context, frame, "variant") == "general") {
output += "\n    ";
var t_9;
t_9 = 10;
frame.set("max", t_9, true);
if(frame.topLevel) {
context.setVariable("max", t_9);
}
if(frame.topLevel) {
context.addExport("max", t_9);
}
output += "\n    ";
var t_10;
t_10 = {"name": "star","size": 1};
frame.set("icon", t_10, true);
if(frame.topLevel) {
context.setVariable("icon", t_10);
}
if(frame.topLevel) {
context.addExport("icon", t_10);
}
output += "\n";
;
}
output += "\n\n";
var t_11;
t_11 = env.getFilter("merge").call(context, env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "classes"),[]),["nh-rating","nh-rating--" + "" + runtime.contextOrFrameLookup(context, frame, "variant")]);
frame.set("classes", t_11, true);
if(frame.topLevel) {
context.setVariable("classes", t_11);
}
if(frame.topLevel) {
context.addExport("classes", t_11);
}
output += "\n\n";
if(runtime.contextOrFrameLookup(context, frame, "value") > runtime.contextOrFrameLookup(context, frame, "max")) {
output += "\n    ";
var t_12;
t_12 = runtime.contextOrFrameLookup(context, frame, "max");
frame.set("value", t_12, true);
if(frame.topLevel) {
context.setVariable("value", t_12);
}
if(frame.topLevel) {
context.addExport("value", t_12);
}
output += "\n";
;
}
output += "\n\n";
if(runtime.contextOrFrameLookup(context, frame, "value") < runtime.contextOrFrameLookup(context, frame, "min")) {
output += "\n    ";
var t_13;
t_13 = runtime.contextOrFrameLookup(context, frame, "min");
frame.set("value", t_13, true);
if(frame.topLevel) {
context.setVariable("value", t_13);
}
if(frame.topLevel) {
context.addExport("value", t_13);
}
output += "\n";
;
}
output += "\n\n<div\n    class=\"";
output += runtime.suppressValue(env.getFilter("join").call(context, runtime.contextOrFrameLookup(context, frame, "classes")," "), env.opts.autoescape);
output += "\"";
frame = frame.push();
var t_16 = env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "data"),{});
if(t_16) {t_16 = runtime.fromIterator(t_16);
var t_14;
if(runtime.isArray(t_16)) {
var t_15 = t_16.length;
for(t_14=0; t_14 < t_16.length; t_14++) {
var t_17 = t_16[t_14][0];
frame.set("[object Object]", t_16[t_14][0]);
var t_18 = t_16[t_14][1];
frame.set("[object Object]", t_16[t_14][1]);
frame.set("loop.index", t_14 + 1);
frame.set("loop.index0", t_14);
frame.set("loop.revindex", t_15 - t_14);
frame.set("loop.revindex0", t_15 - t_14 - 1);
frame.set("loop.first", t_14 === 0);
frame.set("loop.last", t_14 === t_15 - 1);
frame.set("loop.length", t_15);
output += " ";
output += runtime.suppressValue(t_17, env.opts.autoescape);
if(t_18) {
output += "=\"";
output += runtime.suppressValue(t_18, env.opts.autoescape);
output += "\"";
;
}
;
}
} else {
t_14 = -1;
var t_15 = runtime.keys(t_16).length;
for(var t_19 in t_16) {
t_14++;
var t_20 = t_16[t_19];
frame.set("name", t_19);
frame.set("value", t_20);
frame.set("loop.index", t_14 + 1);
frame.set("loop.index0", t_14);
frame.set("loop.revindex", t_15 - t_14);
frame.set("loop.revindex0", t_15 - t_14 - 1);
frame.set("loop.first", t_14 === 0);
frame.set("loop.last", t_14 === t_15 - 1);
frame.set("loop.length", t_15);
output += " ";
output += runtime.suppressValue(t_19, env.opts.autoescape);
if(t_20) {
output += "=\"";
output += runtime.suppressValue(t_20, env.opts.autoescape);
output += "\"";
;
}
;
}
}
}
frame = frame.pop();
output += "\n>";
output += runtime.suppressValue((lineno = 35, colno = 27, runtime.callWrap(runtime.memberLookup((t_1),"render"), "iconTemplate[\"render\"]", context, [{"name": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "icon")),"name"),"size": env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "icon")),"size"),1),"custom": env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "icon")),"custom"),false)}])), env.opts.autoescape);
output += runtime.suppressValue(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "value"),0), env.opts.autoescape);
output += "/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "max"), env.opts.autoescape);
output += "</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["design-system/v3/components/atoms/rating/Rating.html.njk"] , dependencies)