import DateFromTo from '@naturehouse/design-system/components/atoms/date-from-to/DateFromTo';
import DatePickerCalendar from '../../calendar/webComponents/DatePickerCalendar';

export default class ClearCalendarHandler {
    readonly #clearButtons: HTMLButtonElement[];

    readonly #dateFromToElement: DateFromTo;

    readonly #datepickerCalendar: DatePickerCalendar;

    constructor(
        element: HTMLElement,
        modalElement: HTMLElement,
        dateFromToElement: DateFromTo,
        datepickerCalendar: DatePickerCalendar
    ) {
        this.#dateFromToElement = dateFromToElement;
        this.#datepickerCalendar = datepickerCalendar;
        this.#clearButtons = this.#setButtons(element, modalElement);
    }

    public initialize(): void {
        for (const button of this.#clearButtons) {
            button.addEventListener('click', this.#clearCalendar.bind(this));
        }

        this.#dateFromToElement.addEventListener('change', () => {
            this.#handleClearButtonState();
        });
    }

    #clearCalendar(): void {
        this.#datepickerCalendar.clearSelection();
        this.#dateFromToElement.dispatchEvent(new Event('clear'));
    }

    #setButtons(element: HTMLElement, modalElement: HTMLElement): HTMLButtonElement[] {
        const clearButtons: HTMLButtonElement[] = Array.from(
            element.querySelectorAll('[data-role="calendar-clear"]')
        );

        const modalClearButtons: HTMLButtonElement[] = Array.from(
            modalElement.querySelectorAll('[data-role="calendar-clear"]')
        );

        const buttons = [...clearButtons, ...modalClearButtons];

        const arrivalDateFieldClearButton: HTMLButtonElement | null = modalElement.querySelector(
            '[data-name="arrivalDate"] [data-role="clear"]'
        );

        if (arrivalDateFieldClearButton) {
            buttons.push(arrivalDateFieldClearButton);
        }

        return buttons;
    }

    #handleClearButtonState(): void {
        const toggleValue = this.#dateFromToElement.value === '';
        this.#clearButtons.forEach((clearButton) => {
            clearButton.toggleAttribute('hidden', toggleValue);
        });
    }
}
