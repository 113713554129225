var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["@naturehouse/design-system/components/protons/icon/Icon.macro.njk"] = require( "@naturehouse/design-system/components/protons/icon/Icon.macro.njk" );




var shim = require("/builds/natuurhuisje/nature.house/symfony/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["design-system/v3/components/atoms/tool-tip/ToolTip.html.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("@naturehouse/design-system/components/protons/icon/Icon.macro.njk", false, "design-system/v3/components/atoms/tool-tip/ToolTip.html.njk", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("iconTemplate", t_1);
output += "\n\n<nh-tool-tip\n    target-id=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "id"), env.opts.autoescape);
output += "\"\n    placement=\"";
output += runtime.suppressValue(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "placement"),"bottom-start"), env.opts.autoescape);
output += "\"";
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "endpoint"),false)) {
output += " endpoint=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "endpoint"), env.opts.autoescape);
output += "\"";
;
}
if(env.getTest("defined").call(context, runtime.contextOrFrameLookup(context, frame, "show")) === true && runtime.contextOrFrameLookup(context, frame, "show") == true) {
output += " show";
;
}
frame = frame.push();
var t_6 = env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "data"));
if(t_6) {t_6 = runtime.fromIterator(t_6);
var t_4;
if(runtime.isArray(t_6)) {
var t_5 = t_6.length;
for(t_4=0; t_4 < t_6.length; t_4++) {
var t_7 = t_6[t_4][0];
frame.set("[object Object]", t_6[t_4][0]);
var t_8 = t_6[t_4][1];
frame.set("[object Object]", t_6[t_4][1]);
frame.set("loop.index", t_4 + 1);
frame.set("loop.index0", t_4);
frame.set("loop.revindex", t_5 - t_4);
frame.set("loop.revindex0", t_5 - t_4 - 1);
frame.set("loop.first", t_4 === 0);
frame.set("loop.last", t_4 === t_5 - 1);
frame.set("loop.length", t_5);
output += " ";
output += runtime.suppressValue(t_7, env.opts.autoescape);
if(t_8) {
output += "=\"";
output += runtime.suppressValue(t_8, env.opts.autoescape);
output += "\"";
;
}
;
}
} else {
t_4 = -1;
var t_5 = runtime.keys(t_6).length;
for(var t_9 in t_6) {
t_4++;
var t_10 = t_6[t_9];
frame.set("name", t_9);
frame.set("value", t_10);
frame.set("loop.index", t_4 + 1);
frame.set("loop.index0", t_4);
frame.set("loop.revindex", t_5 - t_4);
frame.set("loop.revindex0", t_5 - t_4 - 1);
frame.set("loop.first", t_4 === 0);
frame.set("loop.last", t_4 === t_5 - 1);
frame.set("loop.length", t_5);
output += " ";
output += runtime.suppressValue(t_9, env.opts.autoescape);
if(t_10) {
output += "=\"";
output += runtime.suppressValue(t_10, env.opts.autoescape);
output += "\"";
;
}
;
}
}
}
frame = frame.pop();
output += "\n>\n    ";
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "content"),false)) {
output += "\n        <div slot=\"content\">";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.contextOrFrameLookup(context, frame, "content")), env.opts.autoescape);
output += "</div>\n    ";
;
}
output += "\n    <div slot=\"close\">\n        ";
output += runtime.suppressValue((lineno = 13, colno = 30, runtime.callWrap(runtime.memberLookup((t_1),"render"), "iconTemplate[\"render\"]", context, [{"name": "close","size": 0.875}])), env.opts.autoescape);
output += "\n    </div>\n    <div slot=\"arrow\"></div>\n</nh-tool-tip>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["design-system/v3/components/atoms/tool-tip/ToolTip.html.njk"] , dependencies)