import type DateField from '@naturehouse/design-system/components/atoms/date-field/DateField';
import type DateFromTo from '@naturehouse/design-system/components/atoms/date-from-to/DateFromTo';
import type TravelCompanyInput from '@naturehouse/design-system/components/atoms/travel-company-input/TravelCompanyInput';
import defaultBaseDecimalSystem from '../../../common/Constants';
import GoogleAnalyticsEvent from '../../../common/GoogleAnalyticsEvents';
import HttpClient from '../../../common/HttpClient';
import TravelPeriodStateManager from '../../../common/search/travel-period/TravelPeriodStateManager';
import TravelPeriodUIManager from '../../../common/search/travel-period/TravelPeriodUIManager';
import Modal from '../../../ui/web-components/modal/Modal';
import { getCompanionData, getNumberOfNights } from '../../../util/dataLayer';
import DatePickerCalendar, {
    DatePickerCalendarEvents,
    DatePickerCalendarSelectState
} from '../../calendar/webComponents/DatePickerCalendar';
import HouseDetailTrackingEvent from '../HouseDetailTracking';
import ClearCalendarHandler from './ClearCalendarHandler';
import DetailFormTracking from './DetailFormTracking';
import type DetailPriceList from './detail-price-list/DetailPriceList';
import './detail-price-list/DetailPriceList';

export enum BookingDataParameters {
    ARRIVAL_DATE = 'arrivalDate',
    DEPARTURE_DATE = 'departureDate'
}

export enum TravelPartyDataParameters {
    ADULTS = 'adults',
    CHILDREN = 'children',
    BABIES = 'babies',
    PETS = 'pets'
}

/**
 * @Deprecated An epic is planned to take the search features (travel party / dates etc),
 * and move these to the DS and completely refactor the website integration.
 * For this reason this file is deprecated and should no longer be extended.
 * This file is also not unit tested and is a constant point of discussion.
 */
export default class DetailForm extends HTMLFormElement {
    readonly #calendarModal: Modal;

    readonly #arrivalDepartureElement: DateFromTo = this.querySelector(
        'nh-date-from-to'
    ) as DateFromTo;

    readonly #travelPartyInput: TravelCompanyInput | null = this.querySelector('nh-travel-company');

    readonly #travelPeriodStateManager = TravelPeriodStateManager.getInstance();

    readonly #travelPeriodUIManager = TravelPeriodUIManager;

    #travelPartyModal: Modal | null = null;

    #arrivalDates: string[] = [];

    #dataLayer: Record<string, string> = {};

    #destination = '';

    #validDepartureDatesRoute = '';

    #detailPriceList: DetailPriceList | null = null;

    #departureDates: string[] = [];

    readonly #departureDateField: DateField | null;

    readonly #datepickerCalendar: DatePickerCalendar;

    readonly #clearCalendarHandler: ClearCalendarHandler;

    readonly #detailFormTracking: DetailFormTracking = new DetailFormTracking(
        this as HTMLFormElement
    );

    constructor() {
        super();

        const template = this.querySelector('#datepicker-calendar-content') as HTMLTemplateElement;
        const headerTemplate = this.querySelector(
            '#calendar-input-modal-header'
        ) as HTMLTemplateElement;
        const footerTemplate = this.querySelector(
            '#calendar-input-modal-footer'
        ) as HTMLTemplateElement;

        if (!template || !footerTemplate || !headerTemplate) {
            throw new Error('Templates not found');
        }

        this.#travelPeriodStateManager.attach(this.#travelPeriodUIManager);

        this.#calendarModal = new Modal({
            title: template.dataset.title,
            classList: ['search-form-modal', 'nh-stay-duration'],
            hidden: true
        });

        this.#calendarModal.dataset.pw = 'datepicker-calendar-modal';
        this.#calendarModal.dataset.for = 'nh-stay-duration-detail-form';

        const header = document.importNode(headerTemplate.content, true);
        const content = document.importNode(template.content, true);
        const footer = document.importNode(footerTemplate.content, true);

        document.body.append(this.#calendarModal);

        this.#datepickerCalendar = content.querySelector(
            'datepicker-calendar'
        ) as DatePickerCalendar;

        const doneButton = footer.querySelector(
            '[data-role="close-modal"]'
        ) as HTMLButtonElement | null;

        doneButton?.addEventListener('click', this.#closeModal);

        this.#travelPeriodUIManager.dialog = this.#calendarModal;
        this.#travelPeriodUIManager.travelPeriodInput = this.#arrivalDepartureElement;
        this.#travelPeriodUIManager.calendar = this.#datepickerCalendar;
        this.#travelPeriodUIManager.arrivalDateField = header.querySelector(
            'nh-date-field[data-name="arrivalDate"]'
        );
        this.#departureDateField = header.querySelector('nh-date-field[data-name="departureDate"]');
        this.#travelPeriodUIManager.departureDateField = this.#departureDateField;

        const start = this.#arrivalDepartureElement.getAttribute('start');
        const end = this.#arrivalDepartureElement.getAttribute('end');

        this.#travelPeriodStateManager.checkInDate = start ? new Date(start) : null;
        this.#travelPeriodStateManager.checkOutDate = end ? new Date(end) : null;

        this.#calendarModal.header = header;
        this.#calendarModal.content = content;
        this.#calendarModal.footer = footer;

        this.#clearCalendarHandler = new ClearCalendarHandler(
            this,
            this.#calendarModal,
            this.#arrivalDepartureElement,
            this.#datepickerCalendar
        );

        this.#clearCalendarHandler.initialize();
    }

    public connectedCallback(): void {
        const arrivalDates = this.getAttribute('arrival-dates');
        if (arrivalDates) {
            this.#arrivalDates = JSON.parse(arrivalDates);
        }

        const dataLayer = this.getAttribute('data-layer');
        if (dataLayer) {
            this.#dataLayer = JSON.parse(dataLayer);
        }

        this.#validDepartureDatesRoute = this.getAttribute('valid-departure-dates-route') ?? '';
        this.#destination = this.getAttribute('destination') ?? '';
        this.#detailPriceList = this.querySelector<DetailPriceList>('nh-detail-price-list');

        this.addEventListeners();

        setTimeout(() => {
            this.#travelPartyModal = document.querySelector(
                'nh-modal[data-for="travel-party"]'
            ) as Modal;
            this.#detailFormTracking.trackDetailPageImpressionSqueezely(
                this.#getTravelPartyData(),
                this.#datepickerCalendar.selection,
                this.#destination
            );
            this.#detailFormTracking.trackDetailPageImpression();
        }, 1000);
    }

    #updateAndTrackPriceList(): void {
        if (!this.#datepickerCalendar.selection.start || !this.#datepickerCalendar.selection.end) {
            this.#trackOnUpdatePriceList();
            return;
        }

        this.#closeModal();

        if (!this.#detailPriceList) {
            return;
        }

        this.#detailPriceList.render().then(this.#trackOnUpdatePriceList);
    }

    protected addEventListeners(): void {
        this.addEventListener('submit', this.#handleFormSubmit.bind(this));

        this.#travelPartyInput?.addEventListener(
            'change',
            this.#handleTravelPartyChange.bind(this)
        );

        const setArrivalState = (): void => {
            this.#datepickerCalendar.allowedDates = this.#arrivalDates;
            this.#datepickerCalendar.selectState = DatePickerCalendarSelectState.START;
            this.#travelPeriodUIManager.setInputFocusOnArrivalDateField();
        };

        const setDepartureState = (): void => {
            this.#datepickerCalendar.allowedDates = this.#departureDates;
            this.#datepickerCalendar.selectState = DatePickerCalendarSelectState.END;
            this.#travelPeriodUIManager.setInputFocusOnDepartureDateField();
        };

        this.#arrivalDepartureElement.addEventListener('click', this.#showModal.bind(this));

        this.#arrivalDepartureElement.addEventListener('select', (event): void => {
            const customEvent = event as CustomEvent;
            this.#showModal();

            if (
                customEvent.detail === 'start' ||
                this.#datepickerCalendar.selection.start === null
            ) {
                setArrivalState();
                return;
            }

            setDepartureState();
        });

        this.#datepickerCalendar.addEventListener(
            DatePickerCalendarEvents.DATE_RANGE_SELECTED,
            this.#handleDateRangeSelection.bind(this)
        );
        this.#datepickerCalendar.allowedDates = this.#arrivalDates;

        this.#arrivalDepartureElement.addEventListener('cleared', () => {
            this.#handleCalendarCleared(setArrivalState);
        });

        if (this.#departureDateField) {
            this.#departureDateField.addEventListener('click', setDepartureState);
        }
    }

    #handleTravelPartyChange(): void {
        this.#updateAndTrackPriceList();

        const travelParty = this.#getTravelPartyData();
        const queryParams = new URLSearchParams(window.location.search);
        for (const key in travelParty) {
            if (Object.prototype.hasOwnProperty.call(travelParty, key)) {
                // @ts-ignore
                const value = travelParty[key];

                if (!value) {
                    queryParams.delete(key);
                    continue;
                }

                if (value.toString() === queryParams.get(key)?.toString()) {
                    continue;
                }

                queryParams.set(key, value.toString());
            }
        }

        this.#updateQueryParams(queryParams);

        GoogleAnalyticsEvent.trackEventWithDelay<GoogleAnalytics4TravelPartySearchEvent>(
            HouseDetailTrackingEvent.TRAVEL_PARTY_SELECTION,
            {
                page: 'house-detail',
                ...travelParty
            }
        );
    }

    #handleDateSelectionTracking(selection: DateRangeString): void {
        if (selection.start === null) {
            return;
        }

        if (selection.end === null) {
            GoogleAnalyticsEvent.trackEvent<GoogleAnalytics4SelectDateEvent>(
                HouseDetailTrackingEvent.ARRIVAL_DATE_SELECTION,
                {
                    date: new Date(selection.start)
                }
            );
            return;
        }

        GoogleAnalyticsEvent.trackEvent<GoogleAnalytics4SelectDateEvent>(
            HouseDetailTrackingEvent.DEPARTURE_DATE_SELECTION,
            {
                date: new Date(selection.end)
            }
        );
    }

    #handleDateRangeSelection(e: Event): void {
        const calendar = e.target as DatePickerCalendar;

        const event: CustomEvent = e as CustomEvent;
        const start: Date | null = event.detail.start;
        const end: Date | null = event.detail.end;

        this.fillArrivalDepartureInputs(start, end);
        const selection = calendar.selection;
        this.#handleDateSelectionTracking(selection);

        if (selection.start === null) {
            return;
        }

        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set(BookingDataParameters.ARRIVAL_DATE, selection.start);

        if (selection.end) {
            this.#updateAndTrackPriceList();
            queryParams.set(BookingDataParameters.DEPARTURE_DATE, selection.end);
        } else {
            queryParams.delete(BookingDataParameters.DEPARTURE_DATE);
        }

        this.#updateQueryParams(queryParams);

        calendar.allowedDates = [];
        this.updateDepartureDates(queryParams, calendar).then((): void => {
            calendar.selectState = DatePickerCalendarSelectState.END;
        });
    }

    #updateQueryParams(queryParams: URLSearchParams): void {
        const searchString = queryParams.toString();

        if (searchString === new URLSearchParams(window.location.search).toString()) {
            return;
        }

        const newPath = `${window.location.pathname}?${searchString}`;
        window.history.replaceState(null, '', newPath);
    }

    #handleCalendarCleared(callback: null | (() => void) = null): void {
        this.#datepickerCalendar.allowedDates = this.#arrivalDates;
        if (typeof callback === 'function') {
            callback();
        }

        this.#detailPriceList?.clear();
        this.#travelPeriodStateManager.resetDates();

        const queryParams = new URLSearchParams(window.location.search);
        queryParams.delete(BookingDataParameters.ARRIVAL_DATE);
        queryParams.delete(BookingDataParameters.DEPARTURE_DATE);
        this.#updateQueryParams(queryParams);
    }

    protected fillArrivalDepartureInputs(start: Date | null = null, end: Date | null = null): void {
        const startString = start === null ? '' : start.toISOString().split('T')[0];
        const endString = end === null ? '' : end.toISOString().split('T')[0];

        this.#arrivalDepartureElement.value = JSON.stringify({
            start: startString,
            end: endString
        });

        this.#travelPeriodStateManager.updateCheckIn(start);
        this.#travelPeriodStateManager.updateCheckOut(end);
    }

    protected updateDepartureDates(
        queryParams: StandardObjectInterface,
        calendar: DatePickerCalendar
    ): Promise<void> {
        return HttpClient.get(`${this.#validDepartureDatesRoute}?${queryParams.toString()}`).then(
            (response: StandardObjectInterface): void => {
                if (response && response.departureDates) {
                    calendar.allowedDates = response.departureDates;
                    this.#departureDates = response.departureDates;
                }
            }
        );
    }

    readonly #showModal = (): void => {
        if (this.#calendarModal.isOpen) {
            return;
        }

        this.#calendarModal.open({ bindToElement: this.#arrivalDepartureElement });
    };

    #handleFormSubmit(event: Event): void {
        event.preventDefault();

        const dateRange = this.#datepickerCalendar.selectedDateRange;

        if (!dateRange.start) {
            this.#openCalendarInput(BookingDataParameters.ARRIVAL_DATE);
            return;
        }

        if (!dateRange.end) {
            this.#openCalendarInput(BookingDataParameters.DEPARTURE_DATE);
            return;
        }

        this.#detailFormTracking.processAddToCartData(
            this.#dataLayer,
            this.#getTravelPartyData(),
            getNumberOfNights(dateRange.start, dateRange.end),
            this.#datepickerCalendar.selection
        );

        this.submit();
    }

    #openCalendarInput(arrivalDeparture: string): void {
        const detail = arrivalDeparture === BookingDataParameters.ARRIVAL_DATE ? 'start' : 'end';
        this.#arrivalDepartureElement.dispatchEvent(new CustomEvent('select', { detail }));
        this.#arrivalDepartureElement.dispatchEvent(new Event('click'));
    }

    #getTravelPartyData(): TravelPartyDataInterface {
        if (!this.#travelPartyModal) {
            return this.#getTravelPartyDataFromUrl();
        }
        return getCompanionData(this.#travelPartyModal);
    }

    readonly #closeModal = (): void => {
        if (!this.#calendarModal.isOpen) {
            return;
        }

        this.#calendarModal.close();
    };

    #getTravelPartyDataFromUrl(): TravelPartyDataInterface {
        const urlParams = new URLSearchParams(window.location.search);
        return {
            adults: parseInt(
                urlParams.get(TravelPartyDataParameters.ADULTS) || '0',
                defaultBaseDecimalSystem
            ),
            children: parseInt(
                urlParams.get(TravelPartyDataParameters.CHILDREN) || '0',
                defaultBaseDecimalSystem
            ),
            babies: parseInt(
                urlParams.get(TravelPartyDataParameters.BABIES) || '0',
                defaultBaseDecimalSystem
            ),
            pets: parseInt(
                urlParams.get(TravelPartyDataParameters.PETS) || '0',
                defaultBaseDecimalSystem
            )
        };
    }

    readonly #trackOnUpdatePriceList = (): void => {
        const companionData: TravelPartyDataInterface = this.#getTravelPartyData();
        this.#detailFormTracking.trackDetailPageImpressionSqueezely(
            companionData,
            this.#datepickerCalendar.selection,
            this.#destination
        );
    };
}

if (!customElements.get('detail-form')) {
    customElements.define('detail-form', DetailForm, { extends: 'form' });
}
